export default {
  props: {
    isCreateCustomer: Boolean
  },
  data: () => ({
    searchCustomer: null,
    customers: [],
    sameAsBilling: false,
    form: {}
  })
}
